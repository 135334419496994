{
  "baseFooter": {
    "rights": "Sva prava rezervisana"
  },
  "baseHeader": {
    "menu": {
      "select_language": "Odaberite jezik",
      "account": "Vaš nalog",
      "manage_following": "Upravljajte sadržajem koji pratite",
      "selected_videos": "Odabrani snimci",
      "logout": "Odjavite se",
      "language": "Jezik: {currentLanguage}",
      "my_clips_and_highlights": "Moji klipovi i istaknuto"
    }
  },
  "button": {
    "back": "Nazad",
    "cancel": "Otkažite",
    "confirm": "Potvrdite",
    "no": "br",
    "remove": "Uklonite",
    "download": "Preuzmite",
    "clear_all": "Obrišite sve",
    "fullscreen_enter": "Uđite u režim cijelog ekrana",
    "fullscreen_exit": "Izađite iz režima cijelog ekrana",
    "full": "Ceo",
    "regular": "Regularan",
    "exit": "Izlaz",
    "enter_another_card": "Ubacite drugu karticu",
    "enter_another_code": "Unesite drugi kod",
    "submit_code": "Pošaljite kod",
    "try_again": "Pokušajte ponovo",
    "refresh": "Osvježite",
    "toggle_dark": "Promijenite u dark mode",
    "toggle_langs": "Promijenite jezike",
    "delete": "Obrišite",
    "edit": "Uredite",
    "buy_now": "Kupite sada",
    "go_to_homepage": "Idite na početnu stranicu",
    "slide_back": "Prethodni slajd",
    "slide_forward": "Sljedeći slajd",
    "rename": "Preimenuj",
    "get": "Get"
  },
  "common": {
    "loading": "Učitavanje...",
    "loading_failed": "Učitavanje neuspješno",
    "empty_list": "Lista je prazna. Vratite se kasnije.",
    "empty_search": "Nisu pronađeni rezultati sa \"{searchValue}\" nazivom.",
    "empty_events_list": "Još uvijek nema događaja",
    "data_not_found": "Datoteka nije pronađena. Molimo pokušajte kasnije.",
    "get_mobile_app": "Dobijte najbolje iskustvo \n{appName}"
  },
  "countries": {
    "AF": "Avganistan",
    "AX": "Åland Islands",
    "AL": "Albanija",
    "DZ": "Alžir",
    "AS": "Američka Samoa",
    "AD": "Andora",
    "AO": "Angola",
    "AI": "Angvila",
    "AG": "Antigva i Barbuda",
    "AR": "Argentina",
    "AM": "Jermenija",
    "AW": "Aruba",
    "AC": "Ostrvo Asension",
    "AU": "Australija",
    "AT": "Austrija",
    "AZ": "Azerbejdžan",
    "BS": "Bahamska ostrva",
    "BH": "Bahrein",
    "BD": "Bangladeš",
    "BB": "Barbados",
    "BY": "Belorusija",
    "BE": "Belgija",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermudi",
    "BT": "Butan",
    "BO": "Bolivija",
    "BA": "Bosna i Hercegovina",
    "BW": "Bocvana",
    "BR": "Brazil",
    "IO": "Britanska teritorija indijskog okeana",
    "BG": "Bugarska",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Kambodža",
    "CM": "Kamerun",
    "CA": "Kanada",
    "IC": "Kanarska ostrva",
    "BQ": "Karipska Nizozemska",
    "KY": "Kajmanska ostrva",
    "CF": "Centralna Afrička Republika",
    "EA": "Ceuta i Melilla",
    "TD": "Čad",
    "CL": "Čile",
    "CN": "Kina",
    "CX": "Božićno ostrvo",
    "CV": "Zelenortska ostrva",
    "CC": "Kokosova ostrva",
    "CO": "Kolumbija",
    "KM": "Komorska ostrva",
    "CG": "Kongo",
    "CK": "Kukova ostrva",
    "CR": "Kostarika",
    "HR": "Hrvatska",
    "CU": "Kuba",
    "CY": "Kipar",
    "CZ": "Češka",
    "DK": "Danska",
    "DG": "Dijego Garsija",
    "DJ": "Džibuti",
    "DM": "Dominika",
    "DO": "Dominikanska Republika",
    "EC": "Ekvador",
    "EG": "Egipat",
    "SV": "El Salvador",
    "GQ": "Ekvatorijalna Gvineja",
    "ER": "Eritreja",
    "EE": "Estonija",
    "ET": "Etiopija",
    "FK": "Foklandska Ostrva",
    "FO": "Farska Ostrva",
    "FJ": "Fidži",
    "FI": "Finska",
    "FR": "Francuska",
    "GF": "Francuska Gvajana",
    "PF": "Francuska Polinezija",
    "TF": "Francuske Južne Teritorije",
    "GA": "Gabon",
    "GM": "Gambija",
    "GE": "Gruzija",
    "DE": "Njemačka",
    "GH": "Gana",
    "GI": "Gibraltar",
    "GR": "Grčka",
    "GL": "Grenland",
    "GD": "Grenada",
    "GP": "Gvadalupa",
    "GU": "Guam",
    "GT": "Gvatemala",
    "GG": "Gernzi",
    "GN": "Gvineja",
    "GW": "Gvineja-Bisao",
    "GY": "Gvajana",
    "HT": "Haiti",
    "HN": "Honduras",
    "HU": "Mađarska",
    "IS": "Island",
    "IN": "Indija",
    "ID": "Indonezija",
    "IR": "Iran",
    "IQ": "Irak",
    "IE": "Irska",
    "IL": "Izrael",
    "IM": "Ostrvo Man",
    "IT": "Italija",
    "JM": "Jamajka",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazahstan",
    "KE": "Kenija",
    "XK": "Kosovo",
    "KI": "Kiribati",
    "KW": "Kuvajt",
    "KG": "Kirgistan",
    "LV": "Latvija",
    "LB": "Liban",
    "LS": "Lesoto",
    "LR": "Liberija",
    "LY": "Libija",
    "LI": "Lihtenštajn",
    "LT": "Litvanija",
    "LU": "Luksemburg",
    "MK": "Sjeverna Makedonija",
    "MG": "Madagaskar",
    "MW": "Malavi",
    "MY": "Malezija",
    "MV": "Maldivi",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Maršalska Ostrva",
    "MQ": "Martinik",
    "MR": "Mauritanija",
    "MU": "Mauricijus",
    "YT": "Majot",
    "MX": "Meksiko",
    "FM": "Mikronezija",
    "MD": "Moldavija",
    "MC": "Monako",
    "MN": "Mongolija",
    "ME": "Crna Gora",
    "MS": "Montserat",
    "MA": "Maroko",
    "MZ": "Mozambik",
    "MM": "Mjanmar",
    "NA": "Namibija",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Nizozemska",
    "NC": "Nova Kaledonija",
    "NZ": "Novi Zeland",
    "NI": "Nikaragva",
    "NE": "Niger",
    "NG": "Nigerija",
    "NU": "Niue",
    "NF": "Ostrvo Norfok",
    "MP": "Sjeverna Marijanska Ostrva",
    "NO": "Norveška",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PA": "Panama",
    "PG": "Papua Nova Gvineja",
    "PY": "Paragvaj",
    "PE": "Peru",
    "PN": "Ostrva Pitkern",
    "PH": "Filipini",
    "PL": "Poljska",
    "PT": "Portugal",
    "PR": "Portoriko",
    "QA": "Katar",
    "RO": "Rumunija",
    "RW": "Ruanda",
    "WS": "Samoa",
    "SM": "San Marino",
    "SA": "Saudijska Arabija",
    "SN": "Senegal",
    "RS": "Srbija",
    "SC": "Sejšeli",
    "SL": "Sijera Leone",
    "SG": "Singapur",
    "SX": "Sint Maarten",
    "SK": "Slovačka",
    "SI": "Slovenija",
    "SO": "Somalija",
    "SB": "Solomonova Ostrva",
    "ZA": "Južna Afrika",
    "GS": "Južna Džordžija i Južna Sendvička Ostrva",
    "SS": "Južni Sudan",
    "ES": "Španija",
    "LK": "Šri Lanka",
    "SD": "Sudan",
    "SR": "Surinam",
    "SZ": "Esvatini",
    "SE": "Švedska",
    "CH": "Švicarska",
    "TW": "Tajvan",
    "TJ": "Tadžikistan",
    "TH": "Thajland",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad i Tobago",
    "TA": "Tristan da Cunha",
    "TN": "Tunis",
    "TR": "Turska",
    "TM": "Turkmenistan",
    "TC": "Turks i Caicos",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukrajina",
    "AE": "Ujedinjeni Arapski Emirati",
    "UY": "Urugvaj",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venecuela",
    "WF": "Wallis i Futuna",
    "YE": "Jemen",
    "ZM": "Zambija",
    "ZW": "Zimbabve",
    "VG": "Britanska Djevičanska Ostrva",
    "BN": "Brunej",
    "CD": "Demokratska Republika Kongo (Zair)",
    "CW": "Kurakao",
    "TL": "Istočni Timor",
    "HK": "Hong Kong ",
    "CI": "Obala Slonovače",
    "LA": "Laos",
    "MO": "Makao ",
    "KP": "Sjeverna Koreja",
    "PS": "Palestina",
    "RE": "Reinion",
    "RU": "Rusija",
    "KR": "Južna Koreja",
    "SY": "Sirija",
    "TZ": "Tanzanija",
    "VI": "Američka Djevičanska Ostrva",
    "GB": "Ujedinjeno Kraljevstvo",
    "US": "Sjedinjene Državen",
    "VA": "Vatikan",
    "VN": "Vijetnam",
    "EH": "Zapadna Sahara"
  },
  "errors": {
    "incomplete_number": "Broj vaše kartice je nepotpun!",
    "invalid_number": "Broj vaše kartice je neispravan!",
    "incomplete_expiry": "Datum isticanja kartice je nepotpun!",
    "invalid_expiry_month_past": "Godina isticanja vaše kartice je prošla!",
    "invalid_expiry_year_past": "Godina isticanja vaše kartice je prošla!",
    "invalid_expiry_year": "Godina isticanja vaše kartice je neispravna!",
    "incomplete_cvc": "Verifikacioni kod vaše kartice je nepotpun!",
    "device_limit_exceeded": "Prekoračen limit uređaja!",
    "passwords_dont_match": "Lozinke se ne podudaraju!",
    "vod_corrupted_files": "Ovaj video nije moguće učitati",
    "expired_session_error": "Vaša sesija je istekla. Molimo ponovo se prijavite.",
    "vod_files_not_found": "UUps! Video nije pronađen",
    "vod_playback_failed": "UUps! Neuspješno ponovno puštanje zahtevanog videa",
    "page_not_found": "Stranica nije pronađena",
    "event_not_found": "Događaj nije pronađen",
    "league_not_found": "Liga nije pronađena",
    "team_not_found": "Tim nije pronađen",
    "could_not_find_page": "Žao nam je, nismo uspjeli pronaći traženu stranicu.",
    "could_not_find_event": "Žao nam je, nismo uspjeli pronaći traženi događaj.",
    "could_not_find_team": "Žao nam je, nismo uspjeli pronaći traženi tim.",
    "could_not_find_league": "Žao nam je, nismo uspjeli pronaći traženu ligu.",
    "could_not_find_results": "Žao nam je, nema rezultata za vašu pretragu.",
    "default_error_message": "Greška na serveru. Molimo pokušajte kasnije.",
    "ERR400GN00": "Loš zahtjev",
    "ERR401GN00": "Neovlašćeno",
    "ERR402GN00": "Potrebno plaćanje",
    "ERR403GN00": "Nije dozvoljeno",
    "ERR404GN00": "Nije pronađeno",
    "ERR409GN00": "Preklapanje",
    "ERR422GN00": "Sadržaj nije moguće obraditi",
    "ERR451GN00": "Nedostupno usled zakonskih ograničenja",
    "ERR400GN11": "Neispravna identifikacija",
    "ERR400GN12": "Ova polja nije moguće ažurirati",
    "ERR500GN00": "Greška na internom serveru",
    "ERR501GN00": "Nije implementirano",
    "ERR502GN00": "Bad Gateway",
    "ERR503GN00": "Nedostupna usluga",
    "ERR504GN00": "Gateway Timeout",
    "ERR400UR11": "Obavezno je ime i prezime",
    "ERR400UR12": "Obavezna je imejl adresa",
    "ERR400UR13": "Korisnik već ima unetu kreditnu karticu",
    "ERR400UR14": "Molimo popunite polja sa imenom, prezimenom i imejl adresom pre nego što sačuvate kreditnu karticu",
    "ERR400UR17": "Korisnik nema kreditnu karticu za uređivanje",
    "ERR400UR18": "Pretplata je već otkazana",
    "ERR400UR19": "Pretplata nije aktivna",
    "ERR400UR20": "Obavezno korisničko ime i lozinka",
    "ERR400UR21": "Nalog nije aktivan",
    "ERR401UR11": "Pogrešno korisničko ime ili lozinka. Molimo provjerite svoje podatke.",
    "ERR404UR00": "Korisnik nije pronađen",
    "ERR404UR11": "Narudžba nije pronađena",
    "ERR404UR12": "Pretplata nije pronađena",
    "ERR404UR13": "Korisnik ne postoji",
    "ERR404UR14": "Profil ne postoji",
    "ERR404UR15": "Nije pronađena karftica za ovog korisnika",
    "ERR404UR16": "Video nije pronađen na listi za gledanje",
    "ERR409UR11": "Klub je već dodat",
    "ERR409UR12": "Korisnik je već registrovan",
    "ERR400CM11": "Neuspješno plaćanje",
    "ERR400CM12": "Neuspješna narudžba",
    "ERR400CM13": "Prijenos sredstava neuspješan",
    "ERR400CM14": "Neuspješno plaćanje pretplate",
    "ERR400CM15": "Molimo provjerite podatke na vašoj kreditnoj kartici",
    "ERR400CM16": "Platforma nije podržana",
    "ERR404CM11": "Nije moguće dobiti pretplatu",
    "ERR404CM12": "Pristupni kod nije pronađen",
    "ERR409CM11": "Pristupni kod se već koristi",
    "ERR404EV11": "Nije moguće dobiti informacije o događaju",
    "ERR404EV12": "Događaj ili video na zahtev nije pronađen",
    "ERR400ID11": "Neispravan identitet",
    "ERR400ID12": "Neispravan deo identiteta",
    "ERR400ID13": "Identitet se ne može dodati na listu klubova",
    "ERR400ID14": "Ne poklapaju se identitet i deo identiteta",
    "ERR404ID00": "Identitet nije pronađen",
    "ERR404ID11": "Identitet se ne može povratiti",
    "ERR404PR00": "Proizvod nije pronađen",
    "ERR404PR11": "Nije moguće dobiti informacije o ppv",
    "ERR409PR12": "Proizvod je već kupljen",
    "ERR400TG11": "Neuspješno pravljenje obeleživača",
    "ERR403TG11": "Korisnik ne može da ažurira globalne obeleživače",
    "ERR403TG12": "Korisnik ne može da napravi globalne obeleživače",
    "ERR403TG13": "Korisnik ne može da izbriše obeleživače koje nije sam napravio",
    "ERR403TG14": "Korisnik ne može da izbriše globalne obeleživačeUser cannot delete global tags",
    "ERR404TG00": "Obeleživač nije pronađen",
    "ERR400CL11": "Neuspješno pravljenje klipa",
    "ERR404TM00": "Tim nije pronađen",
    "ERR404VD11": "Video na zahtev ne može da se povrati",
    "ERR500NT12": "Neuspješno slanje lozinke za resetovanje imejla",
    "ERR401AT11": "Obavezan token",
    "ERR401AT12": "Neispravan token za resetovanje lozinke",
    "ERR401AT13": "Neispravan token za osvježavanje",
    "ERR401AT14": "Nepoznat izdavalac",
    "ERR401AT15": "Tip tokena nije podržan",
    "ERR401AT16": "Neispravan token",
    "ERR401AT17": "Token je istekao",
    "ERR401AT18": "Neuspješno dobijanje imejla sa tokenom",
    "ERR401AT19": "Korisnik nije pronađen",
    "popup_closed": "Popup je zatvoren",
    "login_failed": "Prijava nije uspjela",
    "ERR400UR24": "Stara lozinka je pogrešna. \nMolimo provjerite svoju akreditaciju",
    "live_stream_error_message": "Ups! Izgleda da je došlo do malog kašnjenja. \nNaš tim aktivno radi na što bržem rješavanju problema. \nHvala vam na strpljenju!"
  },
  "footer_links": {
    "about_us": "O nama",
    "faq": "Često postavljana pitanja",
    "privacy_policy": "Privatnost",
    "terms_of_service": "Uslovi",
    "custom": "{custom}"
  },
  "labels": {
    "access_code": "Unesite pristupni kod",
    "ppv_price": "Ppv {price}",
    "email": "Imejl",
    "fullName": "Puno ime",
    "archive": "Arhiva",
    "videos": "Video snimci",
    "category": "Kategorija",
    "league": "Liga",
    "continue_with": "Nastavite sa {provider}",
    "panoramic_mode": "Nalazite se u panoramskom modu",
    "password": "Lozinka",
    "sign_in": "Prijavite se",
    "sign_up": "Registrujte se",
    "continue": "Nastavite",
    "newest": "Najnoviji",
    "oldest": "Najstariji",
    "dark": "Tamno",
    "light": "Svijetlo",
    "here": "Ovdje",
    "more": "Više",
    "less": "Manje",
    "powered_by": "Pokretanje pomoću",
    "forgot_password": "Zaboravili ste lozinku",
    "reset_password": "Molimo unesite novu lozinku",
    "password_not_match": "Lozinka nije ispravna",
    "see_all": "Vidite sve>",
    "highlights": "Istaknuto | Istaknuti",
    "clip": "Klip | Klipovi",
    "tag": "Obeleživač | Obeleživači",
    "latest": "Najnovije",
    "upcoming": "Očekuje se",
    "statistics": "Statistika",
    "stats": "Stats",
    "subscriptions": "Pretplate",
    "duration": "Trajanje: ističe {duration} {units} nakon kupovine | Trajanje: ističe {duration}",
    "durationRenew": "Trajanje: biće obnovljeno {duration} {units} nakon kupovine | Trajanje: biće obnovljeno {duration}",
    "limited_access": "Ograničen pristup",
    "access_time": "Dobićete pristup",
    "full_access": "Neograničen pristup",
    "ppv_tickets": "ppv Karte | ppv Karta",
    "live": "Uživo",
    "events": "Događaji",
    "all_results": "Svi rezultati",
    "save": "Sačuvaj",
    "password_old": "Stara lozinka",
    "password_new": "Nova lozinka",
    "password_repeat": "Ponovite lozinku",
    "enter_new_password": "Unesite novu lozinku",
    "enter_old_password": "Unesite staru lozinku",
    "confirm_new_password": "Potvrdite novu lozinku",
    "min_8_characters": "Minimalno 8 karaktera",
    "update": "Ažurirajte",
    "update_card": "Ažurirajte karticu",
    "date_added": "Datum koji je dodat:",
    "release_date": "Datum puštanja",
    "my_club": "Moj Klub",
    "select_your_club": "Izaberite Svoj Klub",
    "date": "Datum",
    "type": "Vrsta",
    "name": "Naziv",
    "amount": "Količina",
    "country": "Država",
    "full_name": "Puno ime",
    "phone": "Telefon",
    "search": "Pretraga",
    "search_by_sport_type": "Pretraga prema vrsti sporta",
    "load_more": "Učitajte još",
    "follow": "Pratite",
    "following": "Sljedeći",
    "suggested": "Predloženo",
    "go_to_event": "Idite na događaj",
    "go_to_account": "Idite na nalog",
    "go_to_main_page": "Idite na glavnu stranicu",
    "page_not_found": "Stranica nije pronađena",
    "total": "Ukupno",
    "all": "Sve",
    "or": "ili",
    "sport_type": "Vrsta sporta",
    "event_date": "Datum događaja",
    "anytime": "Bilo kada",
    "today": "Danas",
    "yesterday": "Jučer",
    "past_7_days": "Poslednjih 7 dana",
    "past_30_days": "Poslednjih 30 dana",
    "past_90_days": "Poslednjih 90 dana",
    "years": "Godine | godina | godine",
    "months": "Mjeseci | mjesec | mjeseci",
    "weeks": "Nedjelje | nedjelja | nedjelje",
    "days": "Dani | dan | dani",
    "hours": "Sati | sat | sati",
    "minutes": "Minute | minuta | minute",
    "seconds": "Sekunde | sekunda | sekunde",
    "terms": "Uslovi korišćenja",
    "policy": "Politika privatnosti",
    "next": "Dalje",
    "skip": "Preskočite",
    "search_input_placeholder": "Pretražite škole, klubove, lige itd.",
    "modal_labels": {
      "delete_tag": "Da li ste sigurni da želite da uklonite ove obeleživače? |Da li ste sigurni da želite da uklonite ovaj obeleživač?",
      "delete_profile": "Da li ste sigurni da želite da obrišete svoj profil?",
      "delete_clip": "Da li ste sigurni da želite da uklonite ovaj klip?",
      "delete_highlight": "Da li ste sigurni da želite da obrišete istaknuto?"
    },
    "gameType_labels": {
      "practice": "Vježba",
      "training": "Trening",
      "other": "Ostalo",
      "game": "Utakmica"
    },
    "submit": "Pošaljite",
    "active": "Aktivno",
    "inactive": "Neaktivno",
    "purchase": "Kupite",
    "change_product": "Promijenite proizvod",
    "views": "Nema pregleda | {count} pregled | {count} pregledi",
    "game_videos": "Snimci utakmica",
    "your_clips_highlight": "Vaši klipovi/istaknuto",
    "game_highlights": "Utakmica istaknuto",
    "player_highlights": "Igrač istaknuto",
    "watch_full_game": "Gledajte cijelu utakmicu",
    "create_highlight": "Kreirajte istaknuto",
    "share": "Podijelite",
    "bookmark_add": "Dodajte u izabrano",
    "bookmark_remove": "Uklonite iz izabranog",
    "latest_events": "Najnoviji događaji",
    "latest_highlights": "Najnovije istaknuto",
    "commerce_category_labels": {
      "member": "Član",
      "free": "Besplatno",
      "ppv": "ppv",
      "subscription": "Pretplata",
      "rental": "Rentiranje",
      "premium": "Premijum"
    },
    "lock_event_message": {
      "member": "Molimo prijavite se da biste gledali utakmicu",
      "geo_blocking": "Žao nam je, ali video koji želite da gledate nije dostupan za vaš region",
      "event_restricted": "Ovaj događaj nije dostupan na vašoj lokaciji",
      "premium": "Pretplatite se na Gledajte",
      "subscription": ""
    },
    "main": "Glavno",
    "add_to_selected": "Dodajte u Izbor",
    "remove_from_selected": "Uklonite iz Izbora",
    "team_1_logo": "Tim 1 Logo",
    "team_2_logo": "Tim 2 Logo",
    "team": "Tim",
    "postal_code": "Poštanski broj",
    "search_event_league_team": "Potražite događaj, ligu ili tim",
    "back_to_site": "Povratak na stranicu",
    "done": "Gotovo",
    "search_teams": "Timovi za pretragu",
    "game_recap": "Rezime igre",
    "play_by_play": "Play-by-play",
    "your_clips": "Tvoji klipovi"
  },
  "locales": {
    "en": "English",
    "de": "German",
    "es": "Spanish",
    "pt": "Portuguese",
    "uk-UA": "Ukrainian",
    "pl": "Polish",
    "fr": "French",
    "he": "Hebrew",
    "it": "Italian",
    "ca": "Catalan",
    "ko": "Korean",
    "zh": "Chinese",
    "bs": "Lokalni",
    "title": "Izaberite jezik za veb-sajt"
  },
  "not-found": "Nije pronađeno",
  "pages": {
    "account": {
      "account_delete_button": "Izbrišite nalog",
      "account_delete_confirm": "Da li ste sigurni da želite da izbrišete svoj profil?",
      "account_update_successfully": "Vaš nalog je uspješno ažuriran!",
      "account_delete_successfully": "Vaš nalog je uspješno izbrisan!",
      "account_delete_description": "Ova radnja se ne može poništiti.",
      "tab_general_title": "Opšte",
      "tab_password_title": "Lozinka",
      "tab_subscription_title": "Pretplata",
      "tab_ppv_title": "PPV kartice",
      "tab_ppv_no_ppv": "Nemate PPV kartice. \nMolimo idite na stranicu događaji i kupite karticu.",
      "tab_ppv_access_text": "PPV kartica omogućava pristup",
      "tab_billing_title": "Informacije o naplati",
      "tab_devices_title": "Uređaji"
    },
    "account_billing": {
      "card_holder_name": "Ime na kartici",
      "card_number": "Broj kartice",
      "card_expiry_date": "Datum isticanja",
      "card_cvv": "CVC / CVV",
      "button_back_to_billing_info": "Nazad na informacije o obračunu",
      "payment_history_empty_text": "Vaša istorija plaćanja je prazna",
      "payment_history_title": "Istorija plaćanja",
      "alert_credit_card_save_success": "Podaci o vašoj kreditnoj kartici su uspješno sačuvani",
      "alert_credit_card_update_success": "Podaci o vašoj kreditnoj kartici su uspješno ažurirani",
      "label_modal_confirm_credit_card_delete": "Ako izbrišete kreditnu karticu, vaša pretplata će takođe biti otkazana. Sadržaj će vam biti dostupan do isteka datuma plana. Da li ste sigurni da želite da izbrišete kreditnu karticu? ",
      "alert_credit_card_delete_success": "Vaša kreditna kartica je uspješno izbrisana",
      "payment_in_progress": "Plaćanje u toku",
      "payment_in_progress_message": "Molimo vas da ne zatvarate ovu stranicu dok se vaša uplata ne obradi."
    },
    "account_password": {
      "alert_password_update_success": "Vaša lozinka je uspješno promijenjena"
    },
    "account_subscription": {
      "button_subscribe_now": "Pretplatite se sada",
      "payment_details": "{title}: Detalji plaćanja",
      "active_subscription": "Aktivna pretplata",
      "subscription_expires": "Pretplata ističe {date}",
      "renew_subscription_expires": "Pretplata se obnavlja.Sljedeći datum naplate {date}",
      "subscription_next_charge_date": "Sljedeća naplata| Datum sljedeće naplate ",
      "subscription_expiration_date": "Ističe do",
      "list_subscriptions_empty": "Lista dostupnih pretplata je prazna. Vratite se kasnije",
      "subscription_due_date": "Datum dospijeća",
      "subscription_last_payment_date": "Datum posljednje uplate",
      "button_subscription_cancel": "Otkažite pretplatu",
      "button_subscription_keep": "Zadržite pretplatu",
      "redeem_access_code_label": "Otkupite pristupni kod ovdje",
      "redeem_access_code_title": "Otkupite svoj pristupni kod",
      "or_select_plan": "ili izaberite svoj plan",
      "button_back_to_my_subscription": "Nazad na moju pretplatu",
      "button_back_to_my_tickets": "Nazad na moje kartice",
      "checkbox_apply_as_new_credit_card": "Informacije za čuvanje kreditne kartice",
      "label_plan_amount": "Planirajte iznos",
      "label_taxes": "Takse",
      "label_total_billed": "Ukupno naplaćeno",
      "label_full_access": "Puni pristup",
      "label_limited_access": "Ograničeni pristup",
      "label_full_access_description": "Puni pristup svim sadržajima na vebsajtu",
      "label_limited_access_description": "Ograničeni pristup sadržajima na vebsajtu",
      "alert_access_code_redeemed_success": "Pristupni kod uspješno otkupljen",
      "alert_access_code_redeem_not_found": "Traženi pristupni kod ne postoji",
      "alert_access_code_redeem_code_used": "Traženi pristupni kod se već koristi",
      "alert_subscription_cancel_success": "Pretplata uspješno otkazana",
      "tab_my_subscriptions": "Moje pretplate",
      "tab_all_subscriptions": "Sve pretplate",
      "no_subscriptions_text": "Nema aktivnih pretplata na listi. Vratite se kasnije",
      "cancel_subscription_modal_title": "Da li ste sigurni da želite da otkažete svoju pretplatu?",
      "cancel_subscription_modal_subtitle": "Vaša pretplata će biti otkazana na kraju obračunskog perioda {date}. Možete se predomisliti bilo kada prije ovog datuma."
    },
    "auth_login": {
      "device_no_longer_allowed_error": "Vaš trenutni uređaj nije više na listi dozvoljenih uređaja. Milomo ponovo se prijavite."
    },
    "auth_login_limit": {
      "device_limit_reached": "Dostignut je limit uređaja",
      "remove_device_to_login": "Molimo uklonite jedan uređaj da biste se logovali",
      "current_device": "Trenutni uređaj"
    },
    "auth_password": {
      "send_reset_link": "Pošaljite link za resetovanje",
      "back_to_sign_in": "Nazad na prijavljivanje",
      "set_new_password": "Postavite novu lozinku",
      "resend_instruction": "Ponovo pošaljite instrukcije",
      "forgot_password_help_text": "Pomoći ćemo vam da resetujete i da se vratite na pravi put.",
      "forgot_password_success_text": "Imejl sa instrukcijama za resetovanje lozinke je poslat na vaš {email}.Ukoliko ne primite imejl za nekoliko minuta, kliknite na dugme ispod Ponovo pošalji instrukcije "
    },
    "auth_sign_in": {
      "welcome_message": "Dobro došli nazad",
      "new_to": "Prvi put ovdje",
      "auth_terms": "Ako nastavite, prihvatate naše {terms_of_service} i {privacy_policy}",
      "sign_in_with_provider": "Prijavite se sa svojim {provider} akreditivima"
    },
    "auth_sign_up": {
      "enter_your_details_below": "Unesite svoje podatke ispod",
      "password_updated_successuly_text": "Vaša lozinka je uspješno ažurirana",
      "already_have_account": "Već imate nalog?",
      "follow_category_header": "Odaberite kategorije koje želite da pratite",
      "back_to_all_teams": "Nazad svim timovima",
      "follow_teams_header": "Odaberite timove koje želite pratiti",
      "back_to_all_categories": "Povratak na sve kategorije"
    },
    "categories": {
      "no_following_category_for_logged_user": "Trenutno nema kategorija koje pratite. \nKliklnite {here} da biste bili u toku sa omiljenim događajima",
      "no_following_category_for_unlogged_user": "Molimo {login} da biste bili u toku sa svojim omiljenim događajima"
    },
    "clips": {
      "title_no_items_with_selected_filter": "Nijedna stavka nije pronađena sa ovim filterom",
      "title_no_clips": "Još uvijek nemate klipove. Idite na snimak i napravite svoj prvi klip!",
      "title_no_highlights": "Još uvijek nemate istaknuti sadržaj. Idite na snimak i napravite svoj prvi istaknuti sadržaj!"
    },
    "club": {
      "favorite_club_creating_successfully_text": "Uspješno ste odabrali klub"
    },
    "clubs": {
      "manage_following": "Upravljajte kategorijama za praćenje",
      "choose_your_club": "Izaberite svoj klub",
      "choose_your_club_message": "Izaberite klub sa kojim ćete biti povezani"
    },
    "events": {
      "event_start_date": "Događaj će početi {date}",
      "time_till_kick_off": "Vrijeme do početka:",
      "event_will_start_in_a_few_min": "Događaj će početi automatski za nekoliko minuta... ",
      "appreciation": "Hvala!",
      "purchased_declined": "Plaćanje neuspješno",
      "failed_purchased_message": "Molimo pokušajte ponovo ili ubacite drugu karticu.",
      "success_purchased": "Uspješno ste obavili kupovinu {subscription}",
      "success_purchased_secondary": "Uspješno ste obavili kupovinu",
      "success_code_applied": "{title} kod uspješno primjenjen",
      "failed_code_applied_title": "Neispravan kod",
      "submit_reedem_code_input_placeholder": "Unesite svoj pristupni kod ovdje",
      "failed_code_applied_message": "Molimo pokušajte ponovo ili unesite novi kod.",
      "failed_already_used_code": "Ovaj kod se već koristi",
      "failed_statistics": "Učitavanje statistike za ovaj događaj nije uspjelo..Molimo pokušajte kasnije. Please",
      "game_video_login_message": "Molimo prijavite se da biste gledali snimak utakmice",
      "tags_login_message": "Molimo prijavite se da biste gledali označeni sadržaj",
      "modal_share_title": "Podjelite ovaj snimak",
      "modal_share_with_time": "Početak u {time}",
      "viewing_conditions": "Odaberite svoje uslove gledanja",
      "watching_event_by_text": "Ovaj događaj gledate ",
      "tabs": {
        "event_videos": {
          "name_the_clip": "Imjenujte klip",
          "create_clip": "Napravite klip",
          "title_no_clips": "Još uvek niste napravili klip",
          "title_enabled_clipping": "Nema klipova na ovoj listi",
          "title_no_clips_not_logged_in": "Molimo prijavite se da biste mogli isjeći klipove sa ove utakmice",
          "clip_creating_successfully_text": "Vaš klip će uskoro biti spreman",
          "clip_deleted_successfully_text": "Isječak je uspješno obrisan",
          "rename_clip": "Preimenuj klip",
          "clip_updating_successfully_text": "Klip je uspješno ažuriran"
        },
        "event_tags": {
          "name_the_tag": "Imjenujte obeleživač",
          "make_tag_global": "Učinite obeleživač dostupnim svima",
          "choose_player_color": "Unesite igrača '#' i boju njegovog tima (opciono)",
          "choose_tag": "Odaberite vrstu obeleživača",
          "choose_team": "Odaberite tim",
          "create_tag": "Napravite obeleživač",
          "update_tag": "Ažurirajte obeleživač",
          "tag_creating_successfully_text": "Obeleživač uspješno napravljen",
          "tag_updating_successfully_text": "Obeleživač uspješno ažuriran",
          "tag_deleting_successfully_text": "Obeleživač uspješno obrisan",
          "title_no_tags": "Još uvijek nije napravljen obeleživač",
          "title_enabled_tagging": "Ne postoje obeleživači na ovoj listi",
          "title_no_tags_not_logged_in": "Molimo prijavite se da biste mogli da napravite obeleživače za ovu utakmicu",
          "is_external_content": "Ovo je eksterni sadržaj, ne možete napraviti obeleživač",
          "filters": {
            "search_input_placeholder": "Pretraga odrijeđenih pretraživača",
            "popup_title": "Filter",
            "by_type": "Prema vrsti",
            "by_team": "Prema timu",
            "by_player_number": "Igrač '#'",
            "clear_all_btn": "Obrišite sve | Obrišite sve ({count}) | Obrišite sve ({count})",
            "show_results_btn": "Prikaži rezultate",
            "no_tags": "Nijedan sadržaj se ne podudara sa odabranim filterom",
            "select_all": "Odaberite sve| Odaberite sve ({count}) | Odaberite sve ({count})"
          },
          "play_all_btn": "Pustite sve",
          "pause_all_btn": "Pauzirajte sve"
        },
        "event_stats": {
          "score_board_title": "Rezultat",
          "game_board_title": "Statistika igre",
          "empty_stats_data": "Statistika je prazna"
        }
      },
      "failed_purchased_message_2": "Pokušajte ponovo ili koristite drugi način plaćanja."
    },
    "league": {
      "all_rounds": "Sva kola"
    },
    "videos": {
      "last_viewed": "Posljednje pregledano",
      "user_interesting": "Takođe vam se može svidjeti",
      "title_no_items": "Dodajte još neke snimke u odabrano",
      "description_no_items": "Ovo je mjesto za vaše omiljene snimke. Sačuvajte, pregledajte i podijelite!"
    }
  },
  "sport_types": {
    "americanFootball": "Američki futbal",
    "beachvolleyball": "Odbojka na pijesku",
    "fieldhockey": "Hokej na travi",
    "icehockey": "Hokej na ledu",
    "rollerhockey": "Hokej na koturaljkama",
    "floorball": "Hokej na parketu",
    "softball": "Softbol",
    "waterpolo": "Vaterpolo",
    "badminton": "Badminton",
    "bowling": "Kuglanje",
    "futsal": "Futsal",
    "lacrosse": "Lakros",
    "rugby": "Ragbi",
    "swimming": "Plivanje",
    "wrestling": "Rvanje",
    "baseball": "Bejzbol",
    "cricket": "Kriket",
    "gymnastics": "Gimnastika",
    "netball": "Netbol",
    "snooker": "Snooker",
    "tennis": "Tenis",
    "basketball": "Košarka",
    "handball": "Rukomet",
    "other": "Ostalo",
    "soccer": "Američki fudbal",
    "football": "Futbal",
    "volleyball": "Odbojka"
  },
  "tags": {
    "other_wow": "ostalo wow",
    "general_bad": "loše",
    "general_good": "dobro",
    "general_drill": "uobičajeno",
    "basketball_dunk": "zakucavanje",
    "basketball_three_point": "trojka",
    "basketball_big_play": "big play",
    "basketball_alley_oop": "alley oop",
    "basketball_assist": "asistencija",
    "basketball_block": "blok",
    "basketball_momentym": "momentum",
    "basketball_money_time": "odlučujući trenutak",
    "basketball_substitution": "Izmjena",
    "soccer_goal": "Gol",
    "soccer_penalty": "Prekršaj",
    "soccer_shot_on_target": "Šut u okvir gola",
    "soccer_yellowcard": "Žuti karton",
    "soccer_redcard": "Crveni karton",
    "soccer_foul": "Faul",
    "soccer_corner_kick": "Udarac iz ugla",
    "soccer_shot": "Pogodak",
    "soccer_free_kick": "Slobodan udarac",
    "soccer_header": "Udarac glavom",
    "soccer_save": "Odbrana golmana",
    "soccer_substitution": "Izmjena",
    "football_touch_down": "tač daun",
    "football_field_goal": "pogodak iz igre",
    "football_big_catch": "Dobro hvatanje",
    "football_big_run": "Dobro trčanje",
    "football_big_hit": "Dobar pogodak",
    "football_kick_return": "Vraćanje udarca",
    "football_punt_return": "Vraćanje lopte",
    "football_kickoff": "Početni udarac",
    "football_substitution": "Izmjena",
    "rugby_break_down": "Probijanje",
    "rugby_conversion": "Pretvaranje",
    "rugby_drop_goal": "dropgol",
    "rugby_garry_owen": "udarac garry owen",
    "rugby_grubber": "Udarac grubber",
    "rugby_knock_on": "Naprijed lopta",
    "rugby_maul": "Mol",
    "rugby_penalty_try": "Udarac iz penala",
    "rugby_substitution": "Izmjena",
    "volleyball_free_ball": "Ponovljeni poen",
    "volleyball_side_out": "Promjena servisa",
    "volleyball_spike": "Smeč",
    "volleyball_dig": "Prijem",
    "volleyball_double": "Dupla",
    "volleyball_mishit": "Nošena lopta",
    "volleyball_shank": "Napucana lopta",
    "volleyball_serve": "Servis",
    "volleyball_set_point": "Set lopta",
    "volleyball_match_point": "Meč lopta",
    "volleyball_substitution": "Izmjena",
    "baseball_stolen_base": "Ukradena baza",
    "baseball_home_run": "Home run",
    "baseball_run": "Poen",
    "baseball_single": "Single",
    "baseball_hit": "Pogodak",
    "baseball_double": "Double",
    "baseball_tripple": "Triple",
    "baseball_strikeout": "Ispucavanje ",
    "baseball_popout": "Popout",
    "baseball_ground_out": "Groundout",
    "baseball_fly_out": "Flajaut",
    "baseball_double_play": "Dabl plej",
    "baseball_triple_play": "Triple plej",
    "baseball_walk": "Šetnja",
    "baseball_top_1st": "Prva polovina prvog ininga",
    "baseball_bottom_1st": "Druga polovina prvog ininga",
    "baseball_top_2nd": "Prva polovina drugog ininga",
    "baseball_bottom_2nd": "Druga polovina drugog ininga",
    "baseball_substitution": "Izmjena",
    "lacrosse_goal": "Gol",
    "lacrosse_shot": "Udarac ka golu",
    "lacrosse_save": "Odbrana golmana",
    "lacrosse_clear": "clear",
    "lacrosse_man_up": "man up",
    "lacrosse_penalty": "Prekršaj",
    "lacrosse_face_off": "face off",
    "lacrosse_ground_ball": "Lopta na zemlji",
    "lacrosse_substitution": "Izmjena",
    "futsal_goal": "Gol",
    "futsal_penalty": "Prekršaj",
    "futsal_shot_on_target": "Udarac ka golu",
    "futsal_yellowcard": "Žuti karton",
    "futsal_redcard": "Crveni karton",
    "futsal_foul": "Faul",
    "futsal_corner_kick": "Udarac iz ugla",
    "futsal_shot": "Udarac ka golu",
    "futsal_free_kick": "Slobodan udarac",
    "futsal_header": "Udarac glavom",
    "futsal_save": "Odbrana golmana",
    "futsal_substitution": "Izmjena",
    "beach_volleyball_free_ball": "Ponovljeni poen",
    "beach_volleyball_side_out": "Promjena servisa",
    "beach_volleyball_spike": "Smeč",
    "beach_volleyball_dig": "Dizanje",
    "beach_volleyball_double": "Dupla",
    "beach_volleyball_mishit": "Nošena lopta",
    "beach_volleyball_shank": "Smeč",
    "beach_volleyball_serve": "Servis",
    "beach_volleyball_set_point": "Set lopta",
    "beach_volleyball_match_point": "Meč lopta",
    "beach_volleyball_substitution": "Izmjena",
    "field_hockey_goal": "Gol",
    "field_penalty_corner": "Kazneni korner",
    "field_ball_possesion": "Lopta u posjedu",
    "field_ball_loss": "Izgubljena lopta",
    "field_turn_over": "Promjena posjeda",
    "field_circle_penetration": "Povreda golmanskog prostora",
    "field_press_fp_hc": "Presing",
    "field_interception": "Presretanje",
    "field_time_penalty": "Kazneno vrijeme",
    "field_hockey_fight": "Tuča",
    "field_hockey_big_hit": "Jak udarac tijelom u tijelo",
    "field_hockey_power_play": "Igrač više",
    "field_hockey_4v4": "4v4",
    "field_hockey_shot": "Udarac",
    "field_hockey_save": "Odbrana golmana",
    "field_hockey_other": "Ostalo",
    "field_hockey_substitution": "Izmjena",
    "hockey_goal": "Gol",
    "hockey_fight": "Tuča",
    "hockey_big_hit": "Jak udarac tijelom u tijelo",
    "hockey_power_play": "Igrač više",
    "hockey_4v4": "4v4",
    "hockey_shot": "Udarac",
    "hockey_save": "Odbrana golmana",
    "hockey_substitution": "Izmjena",
    "roller_hockey_goal": "Gol",
    "roller_penalty_corner": "Korner",
    "roller_ball_possesion": "Lopta u posjedu",
    "roller_ball_loss": "Izgubljena lopta",
    "roller_turn_over": "Promjena posjeda",
    "roller_circle_penetration": "Povreda golmanskog prostora",
    "roller_press_fp_hc": "Presing",
    "roller_interception": "Presretanje",
    "roller_time_penalty": "Isključenje",
    "roller_hockey_fight": "Tuča",
    "roller_hockey_big_hit": "Jak udarac tijelom u tijelo",
    "roller_hockey_power_play": "Igrač više",
    "roller_hockey_4v4": "4v4",
    "roller_hockey_shot": "Udarac",
    "roller_hockey_save": "Odbrana golmana",
    "roller_hockey_other": "Ostalo",
    "roller_hockey_substitution": "Izmjena",
    "handball_goal": "Gol",
    "handball_shot_on_goal": "Udarac na gol",
    "handball_save": "Odbrana golmana",
    "handball_foul": "Faul",
    "handball_red_card": "Crveni karton",
    "handball_yellow_card": "Žuti karton",
    "handball_suspension": "Dvominutno isključenje",
    "handball_penalty_shot": "Kazneni udarac",
    "handball_substitution": "Izmjena",
    "wrestling_wow": "wow",
    "gymnastic_wow": "wow"
  }
}
